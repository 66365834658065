import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import monsterinlove from "../images/monsterinlove.jpg"

const MusicPage = () => (
  <Layout pageInfo={{ pageName: "music" }}>
    
    <Container className="mt-5">
      <SEO title="Music" />
      <h1>Listen to the Dirteez</h1>
      <p>The last three albums have been recorded at <strong>Train en Marche</strong> in Marseille. They are available in digital on Bancamp and Vinyl/CD at one of our show.</p>
      <p>If you want to buy one but you're not lucky enough to see us live any time soon, contact us directly and we'll get it shipped.</p>
      <h2>Latest Release: Monster in Love</h2>
      <Row>
        <Col className="col-12 col-md-6">
            <img className="img-fluid" src={monsterinlove} alt="Monster in Love" />
        </Col>
        <Col className="col-12 col-md-6">
          <iframe class="bandcamp-player" src="https://bandcamp.com/EmbeddedPlayer/album=1587438866/size=large/bgcol=333333/linkcol=ffffff/artwork=none/transparent=true/" seamless><a href="https://dirteez.bandcamp.com/album/monster-in-love">Monster in Love de the Dirteez</a></iframe>
        </Col>
      </Row>
      <h2>Older Albums</h2>
      <Row>
        <Col className="col-12 col-md-6">
          <iframe title="spotify-1" src="https://open.spotify.com/embed/album/75zkwe4vZJncaFOczvYTjR" width="100%" height="430" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </Col>
        <Col className="col-12 col-md-6">
          <iframe class="bandcamp-player-sm" src="https://bandcamp.com/EmbeddedPlayer/album=2124689864/size=large/bgcol=333333/linkcol=ffffff/tracklist=false/artwork=small/transparent=true/" seamless></iframe>
          <iframe class="bandcamp-player-sm" src="https://bandcamp.com/EmbeddedPlayer/album=3127017385/size=large/bgcol=333333/linkcol=ffffff/tracklist=false/artwork=small/transparent=true/" seamless></iframe>
        </Col>
      </Row>
    </Container>

  </Layout>
)

export default MusicPage
